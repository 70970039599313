import { Route, Redirect } from "react-router-dom"
import { useLoadContext, useConfigContext } from "shared/src/libs/contextLib/contextLib"
import { useQuery } from "shared/src/libs/queryLib/queryLib"

export function UnauthenticatedRoute({ children, ...rest }) {
	const { user } = useLoadContext()
	const qsParams = useQuery()
	const redirect = qsParams.get("redirect")
	const { homepage } = useConfigContext()
	return (
		<Route {...rest}>
			{!user.isAuthenticated ? children : <Redirect to={redirect === "" || redirect === null ? homepage : redirect} />}
		</Route>
	)
}
