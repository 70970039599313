import { useState, useRef } from "react"
import { CaptchaElement } from "shared/src/components/CaptchaElement/CaptchaElement"
import { SignUpCardWithImage } from "shared/src/components/SignUpComponents/SignUpCardWithImage"
import { SignUpForm } from "shared/src/components/SignUpComponents/SignUpForm/SignUpForm/SignUpForm"
import { useFormFields } from "shared/src/libs/hooksLib/hooksLib"
import { useSafeHistory } from "shared/src/libs/historyLib/historyLib"
import { validateEmail, validatePassword } from "shared/src/libs/validationLib/validationLib"
import { useLogin } from "shared/src/libs/loginLib/loginLib"
import { useQuery } from "shared/src/libs/queryLib/queryLib"
import { patchUser } from "shared/src/libs/apiLib/apiLib"
import { onError } from "shared/src/libs/errorLib/errorLib"
import "./../SignUp.css"
import "./ConfirmAccount.css"
import { Auth } from "@aws-amplify/auth"
import ReCAPTCHA from "react-google-recaptcha"

export const USER_DESCRIPTION_OPTIONS = [
	"I present my own candidates",
	"I help my colleagues present their candidates",
	"I run my own team that present their own candidates",
	"I look after branding",
	"I look after operations",
]

export function ConfirmAccount() {
	const query = useQuery()
	const email = query.get("email") || ""
	const tempPassword = query.get("tempPassword") || ""
	const captchaRef = useRef<ReCAPTCHA>(null)
	const history = useSafeHistory()
	const [fields, handleFieldChange, updateFields] = useFormFields({
		email: email,
		password: tempPassword,
		newPassword: "",
		confirmNewPassword: "",
		fullName: "",
		phoneNumber: "",
		linkedInURL: "",
		jobTitle: "",
		userDescription: "",
	})
	const [isNextConfirmPage, setNextConfirmPage] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [authResponse, setAuthResponse] = useState(null)
	const performLogin = useLogin({ responseOnly: true, unconfirmed: false })

	function validateForm() {
		return (
			fields.email.length > 0 &&
			validateEmail(fields.email) &&
			fields.password.length > 0 &&
			validatePassword(fields.newPassword) &&
			fields.newPassword === fields.confirmNewPassword
		)
	}
	function validateSecondForm() {
		return fields.fullName.length > 0 && fields.userDescription.length > 0
	}
	function handleSubmit(event) {
		event.preventDefault()
		setIsLoading(true)
		captchaRef.current.execute()
	}

	async function handleCaptchaValidation(validationData: any) {
		try {
			const response = await Auth.signIn(fields.email, fields.password, validationData)
			console.log("response", response)
			await Auth.completeNewPassword(response, fields.newPassword)
			setAuthResponse(response)
			updateFields({ fullName: response.challengeParam.userAttributes.name })
			setIsLoading(false)
			setNextConfirmPage(true)
		} catch (e) {
			onError(e)
			setIsLoading(false)
			captchaRef.current.reset()
		}
	}

	async function handleSubmitSecondForm() {
		try {
			setIsLoading(true)
			if (!authResponse) throw new Error("Authentication response is missing.")
			await patchUser({
				userId: authResponse.username,
				email: authResponse.challengeParam.userAttributes.email,
				name: fields.fullName,
				clientId: authResponse.challengeParam.userAttributes["custom:companyId"],
				clientName: authResponse.challengeParam.userAttributes["custom:company"],
				phone: fields.phoneNumber,
				jobTitle: fields.jobTitle,
				linkedInUrl: fields.linkedInURL,
				userDescription: fields.userDescription,
			})
			await Auth.updateUserAttributes(authResponse, {
				name: fields.fullName,
			})
			const newResponse = await Auth.currentAuthenticatedUser()
			console.log("newResponse", newResponse)
			await performLogin({ response: newResponse })
			history.push("/onboarding")
		} catch (e) {
			onError(e)
			setIsLoading(false)
		}
	}

	return (
		<SignUpCardWithImage size="large">
			<div className="confirm-account-form-container">
				{!isNextConfirmPage && (
					<>
						<SignUpForm
							title={
								<>
									<h2>Welcome to HireAra</h2>
									<h1>Create your account</h1>
								</>
							}
							button={{
								isLoading: isLoading,
								text: "Continue",
								role: "submit",
								onClick: handleSubmit,
								disabled: !validateForm(),
							}}
							fields={[
								{
									label: "Email",
									type: "email",
									placeholder: "Enter your email",
									fieldName: "email",
									initialValue: fields.email,
									disabled: email.length > 0 && fields.email === email,
									autofocus: true,
									autocomplete: "email",
									required: true,
									inputFieldHandler: handleFieldChange,
									description: "Please confirm your email to register new password",
								},
								{
									label: "Temporary Password",
									type: "password",
									placeholder: "Paste temporary password",
									fieldName: "password",
									autocomplete: "current-password",
									required: true,
									initialValue: fields.password,
									inputFieldHandler: handleFieldChange,
									disabled: tempPassword.length !== 0,
								},
								{
									label: "New Password",
									type: "password",
									placeholder: "Create password",
									fieldName: "newPassword",
									autocomplete: "new-password",
									required: true,
									passwordAlert: true,
									initialValue: fields.newPassword,
									inputFieldHandler: handleFieldChange,
								},
								{
									label: "Confirm New password",
									type: "password",
									placeholder: "Confirm password",
									fieldName: "confirmNewPassword",
									autocomplete: "new-password",
									required: true,
									initialValue: fields.confirmNewPassword,
									inputFieldHandler: handleFieldChange,
									description: (
										<span
											style={
												fields.newPassword === fields.confirmNewPassword
													? { display: "none" }
													: { color: "var(--ara-error)" }
											}>
											Password & Confirm Password do not match.
										</span>
									),
								},
							]}
						/>
						<div>
							<CaptchaElement
								ref={captchaRef}
								onChange={handleCaptchaValidation}
								shouldExecuteAfterErrorReset={isLoading}
							/>
						</div>
					</>
				)}

				{isNextConfirmPage && (
					<SignUpForm
						title={
							<>
								<h2>Welcome to HireAra</h2>
								<h1>Create your account</h1>
							</>
						}
						button={{
							isLoading: isLoading,
							text: "Confirm",
							role: "submit",
							onClick: handleSubmitSecondForm,
							disabled: !validateSecondForm(),
						}}
						fields={[
							{
								label: "Full Name",
								type: "text",
								placeholder: "Enter your name",
								fieldName: "fullName",
								initialValue: fields.fullName,
								autofocus: true,
								autocomplete: "name",
								required: true,
								inputFieldHandler: handleFieldChange,
							},
							{
								label: "Phone Number",
								type: "tel",
								placeholder: "Enter number",
								fieldName: "phoneNumber",
								autocomplete: "tel",
								initialValue: fields.phoneNumber,
								inputFieldHandler: handleFieldChange,
							},
							{
								label: "LinkedIn URL",
								type: "url",
								placeholder: "Enter URL",
								fieldName: "linkedInURL",
								autocomplete: "url",
								initialValue: fields.linkedInURL,
								inputFieldHandler: handleFieldChange,
							},
							{
								label: "Job Title",
								type: "text",
								placeholder: "Enter title",
								fieldName: "jobTitle",
								initialValue: fields.jobTitle,
								inputFieldHandler: handleFieldChange,
							},
							{
								label: "What is your Role in the Candidate Presentation Process?",
								type: "select",
								placeholder: "Select an option",
								fieldName: "userDescription",
								required: true,
								initialValue: fields.userDescription,
								inputFieldHandler: updateFields,
								dropdownOptions: USER_DESCRIPTION_OPTIONS,
							},
						]}
					/>
				)}
			</div>
		</SignUpCardWithImage>
	)
}
