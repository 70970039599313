import { Route, Switch, useRouteMatch } from "react-router-dom"
import { refreshAraAccessToken, storeRefreshCode, storeSignature, useSafeHistory } from "shared"
import { NotFound, AccessDenied, AccessExpired } from "shared"
import { UnauthenticatedRoute, AuthenticatedRoute, LoaderFallback, ErrorBoundary, IframePopOutButton } from "shared"
import { OAuthCallback } from "shared/src/components/Integrations/OAuthCallback"
import { lazyWithForcedRefresh } from "shared"
import { Suspense, useMemo } from "react"

const PresentCVDisplay = lazyWithForcedRefresh(
	() => import(/* webpackChunkName: "PresentCVDisplay" */ "Present/PresentCVDisplay")
)
const DigitalCVDisplay = lazyWithForcedRefresh(
	() => import(/* webpackChunkName: "DigitalCVDisplay" */ "DigitalCVDisplay/DigitalCVDisplay")
)
const LinkLoad = lazyWithForcedRefresh(() => import(/* webpackChunkName: "LinkLoad" */ "LinkLoad/LinkLoad"))
const TemplateDisplay = lazyWithForcedRefresh(
	() => import(/* webpackChunkName: "TemplateDisplay" */ "Template/TemplateDisplay/TemplateDisplay")
)
const BullhornEmbed = lazyWithForcedRefresh(
	() => import(/* webpackChunkName: "BullhornEmbed" */ "Bullhorn/BullhornEmbed")
)
const BullhornRedirect = lazyWithForcedRefresh(
	() => import(/* webpackChunkName: "BullhornRedirect" */ "Bullhorn/BullhornRedirect")
)
const ApplyDefaultTemplate = lazyWithForcedRefresh(
	() => import(/* webpackChunkName: "ApplyDefaultTemplate" */ "ApplyDefaultTemplate/ApplyDefaultTemplate")
)

const PublicAddJob = lazyWithForcedRefresh(() => import(/* webpackChunkName: "PublicAddJob" */ "Jobs/PublicAddJob"))
const EntryPoint = lazyWithForcedRefresh(() => import(/* webpackChunkName: "EntryPoint" */ "EntryPoint/EntryPoint"))
const CustomEntryPoint = lazyWithForcedRefresh(
	() => import(/* webpackChunkName: "CustomEntryPoint" */ "EntryPoint/CustomEntryPoint")
)
const IntegrationPage = lazyWithForcedRefresh(
	() => import(/* webpackChunkName: "IntegrationPage" */ "Integrations/IntegrationPage")
)

const refreshTokenPath = "/candidate/link/:shortId"

/**
 * Refreshes the access token and refresh token when the user navigates to the refresh token path
 * Needed to make sure that a refresh token is stored in the browser before login actions take place,
 * as race conditions can break the login if done in the linkload page
 */
export function useRefreshToken() {
	const match = useRouteMatch(refreshTokenPath)
	useMemo(async () => {
		if (match?.isExact) {
			const shortId = match.params?.shortId
			const { refreshExpiry, accessToken } = await refreshAraAccessToken(shortId)
			// save refresh token
			storeRefreshCode(shortId, refreshExpiry * 1000) //js uses milliseconds
			// save access token
			storeSignature(accessToken)
		}
	}, [match?.isExact, match?.params?.shortId])
}

export default function Routes({ mapHomeToCandidatePage = false }) {
	const history = useSafeHistory()
	history.listen((location, action) => {
		window?.rg4js?.("trackEvent", { type: "pageView", path: location.pathname })
	})

	window.Intercom("update", {
		hide_default_launcher: true, // default no chat in iframe/isngle page
	})

	return (
		<ErrorBoundary>
			<Suspense fallback={<LoaderFallback />}>
				<Switch>
					<AuthenticatedRoute path="/singlepageview/application/:appId">
						<PresentCVDisplay />
					</AuthenticatedRoute>
					<AuthenticatedRoute path="/singlepageview/digitalcv/:appId">
						<DigitalCVDisplay />
					</AuthenticatedRoute>
					<AuthenticatedRoute path="/candidate/application/:appId">
						<PresentCVDisplay />
					</AuthenticatedRoute>
					<Route path={refreshTokenPath}>
						<LinkLoad />
					</Route>
					<AuthenticatedRoute assumeSignature={true} path="/candidate/digitalcv/:appId">
						<DigitalCVDisplay />
					</AuthenticatedRoute>
					<AuthenticatedRoute path="/singlepageview/addjob">
						<PublicAddJob />
					</AuthenticatedRoute>
					<AuthenticatedRoute path="/singlepageview/integration">
						<IntegrationPage />
					</AuthenticatedRoute>
					<AuthenticatedRoute path="/singlepageview/template/:templateId">
						<TemplateDisplay />
					</AuthenticatedRoute>
					<AuthenticatedRoute path="/apply">
						<ApplyDefaultTemplate />
					</AuthenticatedRoute>
					<Route path="/oauthcallback">
						<OAuthCallback />
					</Route>
					<Route path="/entrypoint/custom/:platform/:endPoint">
						<CustomEntryPoint />
					</Route>
					<Route path="/entrypoint/:endPoint">
						<EntryPoint />
					</Route>
					<Route path="/bullhorn/:apiKey/:secret">
						<BullhornEmbed />
					</Route>
					<Route path="/bullhornredirect">
						<BullhornRedirect />
					</Route>
					<UnauthenticatedRoute path="/accessexpired">
						<AccessExpired />
					</UnauthenticatedRoute>
					<Route path="/accessDenied">
						<AccessDenied />
					</Route>
					{mapHomeToCandidatePage && (
						<Route path={"/:shortId"}>
							{/* must be last route with path as the variable will take any value{" "} */}
							{/* This is the home page when accessed from candidates.live equivalent to /candidate/link/:shortId */}
							<LinkLoad />
						</Route>
					)}
					<Route>
						<NotFound />
					</Route>
				</Switch>
			</Suspense>
			<IframePopOutButton />
		</ErrorBoundary>
	)
}
