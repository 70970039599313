import { useWindowDimensions } from "shared/src/libs/hooksLib/hooksLib"

import Button from "react-bootstrap/Button"
import Row from "react-bootstrap/Row"
import { BsArrowRepeat } from "react-icons/bs"
import "./LoaderButton.css"
import { AraTooltip, AraTooltipProps } from "../AraTooltip/AraTooltip"

export function LoaderButton({ isLoading = false, className = "", disabled = false, ...props }) {
	return (
		<Button className={`LoaderButton ${className}`} disabled={disabled || isLoading} variant="light" {...props}>
			<div className="lbContent">
				{isLoading && <BsArrowRepeat className="spinning" />}
				{props.children}
			</div>
		</Button>
	)
}

export function LoadingSpinner({
	isLoading,
	style = undefined,
	className = undefined,
}: React.HTMLAttributes<HTMLDivElement> & { isLoading: boolean }) {
	return isLoading ? <BsArrowRepeat style={style} className={`spinning ${className}`} /> : <div></div>
}
// Import the missing type

export function CenteredLoaderButton(props) {
	return (
		<Row>
			<LoaderButton style={{ margin: "auto" }} {...props}>
				{props.children}
			</LoaderButton>
		</Row>
	)
}

export function ImageButton({
	src,
	alt,
	onClick,
	disabled = false,
	centered = true,
	tooltipProps,
	labelOnSmallScreens = true,
	alwaysShowAsLabel = false,
	isLoading,
	...props
}: React.ImgHTMLAttributes<HTMLImageElement> & {
	disabled?: boolean
	centered?: boolean
	tooltipProps?: AraTooltipProps
	labelOnSmallScreens?: boolean
	alwaysShowAsLabel?: boolean
	isLoading?: boolean
}) {
	const { width } = useWindowDimensions({ includeHeight: false })
	const showAsTooltips = width > 768 && !alwaysShowAsLabel
	const btn = (
		<div role="button" className={`image-button ${centered ? "image-button-centered" : ""}`}>
			<img
				{...props}
				className={`${props.className ?? ""} actionButton ${(disabled ?? false) ? "disabledButton" : " clickable"}`}
				alt={alt}
				src={src}
				onClick={(e) => {
					if (!disabled) {
						onClick(e)
					}
				}}
			/>
			<LoadingSpinner isLoading={isLoading} />
		</div>
	)

	return tooltipProps && labelOnSmallScreens ? (
		showAsTooltips ? (
			<AraTooltip {...tooltipProps}>{btn}</AraTooltip>
		) : (
			<div className="image-button-label">
				<div className="image-button-label">{tooltipProps.tooltipLabel}</div>
				{btn}
			</div>
		)
	) : (
		btn
	)
}

export const LoaderFallback = () => {
	return (
		<div className="loader-fallback">
			<div>
				<LoadingSpinner isLoading={true} />
				<h5>Loading...</h5>
			</div>
		</div>
	)
}
