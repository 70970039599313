import { useConfigContext } from "shared/src/libs/contextLib/contextLib"
import ReCAPTCHA from "react-google-recaptcha"
import { forwardRef, useImperativeHandle, useRef } from "react"

function useExponentialBackoff(callback, startDelay = 100, multiplier = 2) {
	const waitTime = useRef(startDelay)
	const delayedCallback = (...args) => {
		setTimeout(() => {
			callback(...args)
		}, waitTime.current)
		waitTime.current = multiplier * waitTime.current
		console.log("delay set to", waitTime.current)
	}
	return delayedCallback
}

interface CaptchaElementProps {
	onChange: (validationData: any) => Promise<void>
	shouldExecuteAfterErrorReset: boolean
}

export const CaptchaElement = forwardRef<ReCAPTCHA, CaptchaElementProps>(
	({ onChange, shouldExecuteAfterErrorReset }: CaptchaElementProps, forwardedRef) => {
		const { captchaKey } = useConfigContext()
		const internalRef = useRef<ReCAPTCHA>(null)

		//expose specific properties and methods to the parent component
		useImperativeHandle(forwardedRef, () => ({
			reset: () => internalRef.current?.reset(),
			execute: () => internalRef.current?.execute(),
		}))

		const resetCallback = (shouldExecute = false) => {
			console.log("resetting captcha")
			internalRef.current?.reset()
			if (shouldExecute) {
				console.log("executing after reset")
				internalRef.current?.execute()
			}
		}
		const delayedReset = useExponentialBackoff(resetCallback)

		function onChangeInternal(token: string) {
			const validationData = { token, dev: process.env.REACT_APP_IS_DEV, test: process.env.REACT_APP_IS_TEST }
			onChange(validationData)
		}

		function onError() {
			console.log("Captcha error detected")
			delayedReset(shouldExecuteAfterErrorReset)
			window?.rg4js?.("send", {
				error: new Error(`Captcha error, will attempt reset${shouldExecuteAfterErrorReset ? " and re-execute" : ""}`),
			})
		}

		return (
			<div style={{ margin: "0 auto", width: "fit-content" }}>
				<ReCAPTCHA
					sitekey={captchaKey}
					onChange={onChangeInternal}
					onErrored={onError}
					size="invisible"
					ref={internalRef}
					badge="inline"
				/>
			</div>
		)
	}
)
