import { useMessagingContext } from "../MessagingContext/MessagingContext"
import "./PopUpMessage.css"

export const PopUpMessage = ({ children }: { children: React.ReactNode }) => {
	const { messageContent, setMessageContent } = useMessagingContext()
	return (
		<div
			className={`pop-up-message ${messageContent ? "pop-up-message-visible" : "pop-up-message-hidden"}`}
			onAnimationEnd={() => {
				setMessageContent(null)
			}}>
			{children}
		</div>
	)
}
