import { useCallback, useState } from "react"
import { Modal } from "./Modal"
import { ModalContext, ModalOptions } from "./ModalContext"

const DEFAULT_MODAL_OPTIONS: ModalOptions = {
	clickOutAnywhere: true,
}

export const ModalContextProvider = ({
	modalContentInit,
	children,
}: {
	modalContentInit?: JSX.Element | null
	children: React.ReactNode
}) => {
	const [[modalContent, modalOptions], setModalContentBase] = useState<[JSX.Element | null, ModalOptions]>([
		modalContentInit,
		DEFAULT_MODAL_OPTIONS,
	])
	const shouldShowModal = modalContent != null
	const [showPreClose, setShowPreClose] = useState(false)

	const setModalContent = useCallback(
		(content: JSX.Element | null, options?: ModalOptions) => {
			setModalContentBase([content, options == null ? DEFAULT_MODAL_OPTIONS : { ...DEFAULT_MODAL_OPTIONS, ...options }])
		},
		[setModalContentBase]
	)

	return (
		<ModalContext.Provider value={{ modalContent, modalOptions, setModalContent, shouldShowModal }}>
			<div className="working-space">
				{children}
				<Modal showPreClose={showPreClose} setShowPreClose={setShowPreClose}>
					{modalContent}
				</Modal>
			</div>
		</ModalContext.Provider>
	)
}
