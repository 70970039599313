import { useState, useRef } from "react"
import "./Login.css"
import { useLogin } from "shared/src/libs/loginLib/loginLib"
import { Link } from "react-router-dom"
import { onError } from "shared/src/libs/errorLib/errorLib"
import ReCAPTCHA from "react-google-recaptcha"
import { useFormFields } from "shared/src/libs/hooksLib/hooksLib"
import { CaptchaElement } from "shared/src/components/CaptchaElement/CaptchaElement"
import { SignUpCardWithImage } from "shared/src/components/SignUpComponents/SignUpCardWithImage"
import { SignUpForm } from "shared/src/components/SignUpComponents/SignUpForm/SignUpForm/SignUpForm"

export function Login() {
	const performLogin = useLogin({ responseOnly: false })
	const captchaRef = useRef<ReCAPTCHA>(null)

	const [fields, handleFieldChange] = useFormFields({
		email: "",
		password: "",
	})
	const [isLoading, setIsLoading] = useState(false)

	function validateForm() {
		return fields.email.length > 0 && fields.password.length > 0
	}

	function handleSubmit(event) {
		event.preventDefault()
		setIsLoading(true)
		captchaRef.current.execute()
	}

	async function onCaptchaChange(validationData) {
		try {
			await performLogin({
				email: fields.email,
				password: fields.password,
				validationData,
			})
		} catch (e) {
			setIsLoading(false)
			captchaRef.current.reset()
			onError(e)
		}
	}

	return (
		<SignUpCardWithImage size="medium">
			<div className="login-form-container">
				<SignUpForm
					title={
						<>
							<h1>Welcome Back!</h1>
							<h2>Please enter your details</h2>
						</>
					}
					button={{
						text: "Login",
						role: "submit",
						isLoading: isLoading,
						onClick: handleSubmit,
						disabled: !validateForm(),
					}}
					fields={[
						{
							label: "Email",
							type: "email",
							placeholder: "Enter email",
							fieldName: "email",
							initialValue: fields.email,
							autofocus: true,
							autocomplete: "email",
							required: true,
							inputFieldHandler: handleFieldChange,
						},

						{
							label: "Password",
							type: "password",
							placeholder: "Enter password",
							fieldName: "password",
							autocomplete: "current-password",
							required: true,
							initialValue: fields.password,
							inputFieldHandler: handleFieldChange,
							description: <Link to="/forgotpassword">Forgot password?</Link>,
						},
					]}
				/>
				<div>
					<CaptchaElement ref={captchaRef} onChange={onCaptchaChange} shouldExecuteAfterErrorReset={isLoading} />
				</div>
			</div>
		</SignUpCardWithImage>
	)
}
