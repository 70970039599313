import { Company, User } from "../../DatabaseObjects/DatabaseObjects"
import { useContext, createContext, ReactNode, useMemo } from "react"
import { RESTRICTABLE_FEATURES, RestrictableFeature } from "./restrictableFeatures"
import {
	ClientProductionFeaturePermissionDefinition,
	FeaturePermissionDefinition,
	UserProductionFeaturePermissionDefinition,
} from "./types"

type Permission = "read" | "write" | "none"
type AdminPermissionKeys = "settings"
type MainPermissionKeys = "settings" | "templates" | "editing" | "branding"
export type PermissionKeys = AdminPermissionKeys | MainPermissionKeys
export type AccessPermissions = {
	[role: string]: {
		[key in PermissionKeys]?: Permission
	}
}

type FeaturePermissions = { [feature in keyof typeof RESTRICTABLE_FEATURES]: boolean }

type FeaturesContextProps = {
	permissions: FeaturePermissions
}

export const FeaturesContext = createContext<FeaturesContextProps>({} as FeaturesContextProps)

export const useFeaturesContext = () => {
	return useContext(FeaturesContext)
}

export const FeaturesProvider = ({
	children,
	company,
	user,
}: {
	children: ReactNode
	company: Company
	user: User
}) => {
	const permissions = useMemo(() => getPermissions(company, user), [company, user])
	return <FeaturesContext.Provider value={{ permissions }}>{children}</FeaturesContext.Provider>
}

function getPermissions(company: Company, user: User): FeaturePermissions {
	return Object.fromEntries(
		Object.entries(RESTRICTABLE_FEATURES).map(([key, feature]: [RestrictableFeature, FeaturePermissionDefinition]) => [
			key,
			getPermission(key, feature, company, user),
		])
	) as FeaturePermissions
}

function isClientAccessControlled(
	featureDefinition: FeaturePermissionDefinition
): featureDefinition is ClientProductionFeaturePermissionDefinition {
	return featureDefinition.clientAccessControlled === true
}

function isUserAccessControlled(
	featureDefinition: FeaturePermissionDefinition
): featureDefinition is UserProductionFeaturePermissionDefinition {
	return featureDefinition.userAccessControlled === true
}

function getPermission(
	featureKey: keyof typeof RESTRICTABLE_FEATURES,
	featureDefinition: FeaturePermissionDefinition,
	company: Company,
	user: User
): boolean {
	if (featureDefinition.releaseState === "unreleased") {
		return (process.env.REACT_APP_IS_TEST === "true" || process.env.REACT_APP_IS_DEV === "true") ?? false
	}
	if (featureDefinition.releaseState === "beta") {
		return (
			(process.env.REACT_APP_IS_BETA === "true" ||
				window.location.href.includes("beta.hireara.ai") ||
				user.betaPermissions?.[featureKey]) ??
			false
		)
	}
	const clientAccess = isClientAccessControlled(featureDefinition)
		? (company?.options?.flags?.[featureKey] ?? featureDefinition.defaultClientAccess ?? false)
		: true // If the feature is not client access controlled, then the client has access to it by default
	const userAccess = isUserAccessControlled(featureDefinition)
		? (user?.permissions?.[featureKey] ?? featureDefinition.defaultUserAccess ?? false)
		: true // If the feature is not user access controlled, then the user has access to it by default
	return clientAccess && userAccess
}

export type AccessForPermission = {
	read: any[]
	write: any[]
	none: any[]
}

export function mapAccessBasedOnPermissions(
	userRole: string,
	accessPermission: PermissionKeys,
	accessForPermission: AccessForPermission,
	accessPermissions: AccessPermissions
) {
	const userPermissions = accessPermissions[userRole]?.[accessPermission] ?? "none"
	if (userPermissions === "write") {
		return [...accessForPermission.write]
	} else if (userPermissions === "read") {
		return [...accessForPermission.read]
	} else if (userPermissions === "none") {
		return [...accessForPermission.none]
	}
}
