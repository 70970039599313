import { createContext, useContext } from "react"
interface ModalContextProps {
	modalContent?: JSX.Element | null
	setModalContent?: (content: JSX.Element | null, options?: ModalOptions) => void
	shouldShowModal: boolean
	modalOptions?: ModalOptions
}

export interface ModalOptions {
	clickOutAnywhere?: boolean
	PreCloseScreen?: PreCloseComponent
}

type PreCloseComponent = ({
	stay,
	leave,
}: {
	stay: (e: MouseEvent) => void
	leave: (e: MouseEvent) => void
}) => JSX.Element

export const ModalContext = createContext<ModalContextProps>({} as ModalContextProps)

export const useModalContext = () => useContext(ModalContext)
