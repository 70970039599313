import { Auth } from "@aws-amplify/auth"
import { API } from "@aws-amplify/api"
import { stripB64Extra } from "../s3Lib/s3Lib"
import { retrieveSignature, storeSignature, getCurrentTokenType } from "../signatureLib/signatureLib"
import { BrowserStorageCache } from "@aws-amplify/cache"
import { App, CustomOutput, UserFromDb } from "../../DatabaseObjects/DatabaseObjects"
import { cleanObject } from "../objLib/objLib"
import { refreshAraAccessToken } from "../signatureLib/refreshLib"
import { safeReload } from "../safeReloadLib/safeReloadLib"

const ttConfig = {
	defaultTTL: 1000 * 60 * 10,
	storage: window.sessionStorage,
	keyPrefix: "apiCache",
}

let ttCache

function getTTCache() {
	if (!ttCache) {
		ttCache = BrowserStorageCache.createInstance(ttConfig)
	}
	return ttCache
}

export function indexArray(array, indexName, defaultVal = "-") {
	//take array of object and return obj with values given by indexName
	return array.reduce((acc, item) => {
		acc[item[indexName] || defaultVal] = item
		return acc
	}, {})
}

interface CognitoUserSession {
	idToken: {
		jwtToken: string
		payload: {
			sub: string
		}
	}
	accessToken: {
		jwtToken: string
	}
}

export async function getCognitoToken(sessionOverride?: CognitoUserSession): Promise<AuthHeaders> {
	try {
		const session = sessionOverride ?? ((await Auth.currentSession()) as unknown as CognitoUserSession)
		if (
			window.location.href?.includes("beta.hireara.ai") &&
			session?.idToken?.payload?.["custom:userType"] !== "beta"
		) {
			console.log("no beta access")
			alert("You have not been granted access to the beta site. Please contact support if you think this is an error.")
			await Auth.signOut()
			return null
		}
		return { Authorization: session?.idToken?.jwtToken, "x-access-token": session?.accessToken?.jwtToken }
	} catch (e) {
		if (e === "No current user") {
			await Auth.signOut()
			return null
		} else {
			throw e
		}
	}
}

async function getSignatureBasedToken(): Promise<AuthHeaders | null> {
	let sig = retrieveSignature()
	if (sig == null) {
		console.log("no token, refreshing")
		//attempt to refresh token
		try {
			const newSig = (await refreshAraAccessToken())?.accessToken
			if (newSig) {
				//successfully refreshed store and continue
				sig = newSig
				storeSignature(sig)
			}
		} catch (e) {
			console.log("token refresh failed", e)
			return null
		}
	}
	if (sig != null) {
		return {
			Authorization: sig,
			"x-access-token": "none",
		}
	}
	return null
}

// async function getTokenAndUser(sessionOverride) {
//   const session = sessionOverride ?? (await Auth.currentSession());
//   return [session.idToken.jwtToken, session.idToken.payload.sub];
// }

export function applyMappingRecursive(mapping, data) {
	if (mapping == null) return mapping
	return Object.entries(mapping).reduce((acc, [key, value]) => {
		if (typeof value === "string") {
			acc[key] = data[value]
		} else {
			acc[key] = applyMappingRecursive(value, data)
		}
		return acc
	}, {})
}

export function createParamsFromMapping(mapping, data, extra = {}) {
	const params = applyMappingRecursive(mapping, data)
	for (let key in extra) {
		params[key] = { ...params[key], ...extra[key] }
	}
	return params
}

type AuthHeaders = { Authorization: string; "x-access-token"?: string }

export async function createSecurityParameters(inputs: {
	sessionOverride?: CognitoUserSession
}): Promise<{ headers: AuthHeaders }> {
	// normal user based auth
	const tokenType = getCurrentTokenType()
	let headers: AuthHeaders | null =
		tokenType === "signature" ? await getSignatureBasedToken() : await getCognitoToken(inputs?.sessionOverride)
	if (headers != null) {
		return { headers }
	}
	// no access, if previously logged in logout was called. Refresh to ensure screen cleared
	safeReload("noUser")
}

export function createApiFunction<OutputType>({
	type,
	path,
	idForPath = "clientId",
	mapping,
	defaultValues = {},
	inputTransform = (x) => x,
	outputTransform = (x) => x,
	errorHandler = (e) => {
		throw e
	},
	condition = () => true,
	shouldUseSecurity = true,
}: EndpointData) {
	return async function apiFunction(inputs: object): Promise<OutputType> {
		condition(inputs)
		const parameters = await createParameters(shouldUseSecurity, mapping, defaultValues, inputs, inputTransform)

		try {
			const fullPath = addIdsToPath(path, inputs?.[idForPath])
			const response = await API[type]?.(global.config.apiGateway.NAME, fullPath, parameters)
			const output = outputTransform(response)
			return output
		} catch (e) {
			return errorHandler(e)
		}
	}
}

const ID_DEPTHS = {
	"/folders": 2,
	"/applications": 3,
	"/links": 4,
	"/views": 5,
	"/output": 4,
	"/logos": 1,
	"/files": 4,
	"/users": 2,
	"/signatures": 1,
	"/jobs": 2,
	"/text-transform": 4,
	"/templates": 2,
	"/clients": 1,
	"/forwarding": 1,
	"/oauth": 1,
}

function addIdsToPath(path: string, id?: string) {
	const idDepth = ID_DEPTHS[path] ?? 1
	if (id == null) {
		return path
	}
	// if (!id.includes("_")) {
	// 	return "/" + id + path
	// }
	const idParts = id.split("_")
	if (idDepth === 1 && idParts.length === idDepth) {
		return "/" + idParts[0] + path
	}
	if (idParts.length === idDepth) {
		return "/" + idParts.slice(0, idParts.length - 1).join("/") + path + "/" + idParts[idParts.length - 1]
	} else if (idParts.length === idDepth - 1) {
		return "/" + id.replaceAll("_", "/") + path
	} else if (idParts.length < idDepth - 1) {
		const missingIdParts = idDepth - 1 - idParts.length
		const extraPath = Array(missingIdParts).fill("/xxxxxxxxxxxx").join("")
		return "/" + id.replaceAll("_", "/") + extraPath + path
	} else {
		throw new Error("Invalid ID for depth")
	}
}

export async function createParameters(shouldUseSecurity, mapping, defaultValues, inputs, inputTransform) {
	const extras = shouldUseSecurity ? await createSecurityParameters(inputs) : {}
	return inputTransform(createParamsFromMapping(mapping, { ...defaultValues, ...inputs }, extras))
}

type BaseApiEndpointSet = {
	path: string
}

type ApiEndpointFunctionSet<T extends string> = {
	[functionName in T]: (inputs: object) => Promise<object>
}

type ApiEndpointSet<T extends string> = BaseApiEndpointSet & ApiEndpointFunctionSet<T>

type EndpointData = {
	type: Method
	path?: string
	idForPath?: string
	mapping: object
	defaultValues?: object
	inputTransform?: (x: InputType) => object
	outputTransform?: (x: InputType) => any
	errorHandler?: (e: { response?: { status?: number }; message?: string }) => any
	condition?: (x: InputType) => boolean
	shouldUseSecurity?: boolean
}

type InputType = {
	[key: string]: any
}

export function makeApiEndpointSet<T extends string>(
	endpointPath: string,
	endpointData: { [endpoint in T]: EndpointData }
): ApiEndpointSet<T> {
	let out: ApiEndpointSet<T> = {} as ApiEndpointSet<T>
	out.path = endpointPath
	for (let [name, data] of Object.entries<EndpointData>(endpointData)) {
		out[name] = createApiFunction({
			...data,
			path: endpointPath,
		})
	}

	return out
}

export const applicationApi = makeApiEndpointSet("/applications", {
	get: {
		type: "get",
		idForPath: "folderId",
		mapping: {
			queryStringParameters: {
				processedOnly: "processedOnly",
				attributes: "attrList",
			},
		},
		defaultValues: {
			processedOnly: true,
			attrList: [
				"totalExperience",
				"averageTenure",
				"score",
				"fileTypes",
				"candidateName",
				"emailAddress",
				"phoneNumber",
				"identifier",
				"linkedUrl",
				"template",
				"coverLetter",
				"templateVersion",
			],
		},
		outputTransform: (output) => indexArray(output, "application_id"),
		errorHandler: (error) => {
			console.log(error)
			if (error.response?.status === 404) {
				console.log("404 received - assume no applications exist")
				return {}
			}
			throw error
		},
	},
	getByExternalId: {
		type: "get",
		idForPath: "folderId",
		mapping: {
			queryStringParameters: {
				platform: "platform",
				externalClientId: "externalClientId",
				externalAppId: "externalAppId",
				byExternalId: "byExternalId",
				attributes: "attrList",
			},
		},
		defaultValues: {
			byExternalId: true,
			attrList: ["candidateName", "identifier", "template", "submittedBy", "receivedTime", "outputName"],
		},
		errorHandler: (error) => {
			console.log(error)
			if (error.response?.status === 404) {
				console.log("404 received - assume no applications exist")
				return {}
			}
			throw error
		},
	},
	add: {
		type: "post",
		inputTransform: cleanObject,
		idForPath: "folderId",
		mapping: {
			queryStringParameters: {
				cvType: "fileType",
				submittedBy: "submittedBy",
				defaultTemplate: "defaultTemplate",
				platform: "platform",
				externalClientId: "externalClientId",
				externalId: "externalId",
				externalUserId: "externalUserId",
				jobId: "jobId",
			},
			body: { fileB64: "fileB64", files: "files", supportingDocuments: "supportingDocuments" },
			defaultValues: {
				fileB64: undefined,
				files: undefined,
				fileType: "cv",
			},
		},
	},
	updateStatus: {
		type: "put",
		idForPath: "appId",
		mapping: {
			queryStringParameters: {
				updateType: "newStatus",
			},
		},
	},
	updateCandidateHeadshot: {
		type: "put",
		idForPath: "appId",
		mapping: {
			queryStringParameters: {
				updateType: "updateType",
			},
			body: {
				headshot: "headshot",
				fileType: "fileType",
			},
		},
		defaultValues: {
			updateType: "update_candidate_headshot",
		},
	},
	runAiParser: {
		type: "put",
		idForPath: "appId",
		mapping: {
			queryStringParameters: {
				updateType: "updateType",
			},
		},
		defaultValues: {
			updateType: "aiParse",
		},
	},
	duplicate: {
		type: "put",
		idForPath: "appId",
		mapping: {
			queryStringParameters: {
				updateType: "updateType",
				newOwner: "newOwner",
			},
		},
		defaultValues: {
			updateType: "duplicate_cv",
		},
	},
	generateTags: {
		type: "put",
		idForPath: "appId",
		mapping: {
			queryStringParameters: {
				updateType: "updateType",
				tags: "tags",
			},
			body: {
				updates: "updates",
			},
		},
		defaultValues: {
			updateType: "generate_tags",
		},
	},
	patch: {
		type: "patch",
		idForPath: "appId",
		mapping: {
			body: "update",
		},
		outputTransform: (output) => true,
		errorHandler: (error) => {
			if (error.response?.status === 405) {
				alert("This CV has been removed from verification")
				return false
			}
		},
	},
	delete: {
		type: "del",
		idForPath: "appId",
		condition: (props) => {
			if (props.confirmFirst === false) {
				return false
			}
			const message = `Are you sure you want to delete this CV?`
			// eslint-disable-next-line no-restricted-globals
			if (confirm(message) === false) {
				throw new Error("Delete canceled")
			}
			return true
		},
		mapping: {},
		defaultValues: {
			confirmFirst: true,
		},
	},
	getSingle: {
		type: "get",
		idForPath: "appId",
		mapping: {
			queryStringParameters: {
				attributes: "attributes",
			},
		},
		inputTransform: (input) => {
			if (input.queryStringParameters.attributes === undefined) {
				delete input.queryStringParameters.attributes
				return input
			}
			return input
		},
		outputTransform: (output) => ({ ...output[0], loadedIndividually: true }),
	},
})

type QSParams = { [param: string]: string }
type MVQSParams = { [param: string]: string[] }

export async function adminGetClients({
	attrList = ["stats", "credits", "paymentType", "options", "billingDate", "manualTracking"],
}) {
	const headers = await getCognitoToken()
	var params: QSParams | MVQSParams = {}
	if (attrList !== undefined) {
		params.attributes = attrList
	}
	try {
		const record = indexArray(
			await API.get(global.config.apiGateway.NAME, "/allclients", {
				queryStringParameters: params,
				headers,
			}),
			"client_id"
		)
		return record
	} catch (error) {
		if (error.response?.status === 404) {
			console.log("404 received - assume no clients exist")
			return {}
		}
		throw error
	}
}

export const clientApi = makeApiEndpointSet("/clients", {
	create: {
		type: "post",
		mapping: {
			queryStringParameters: {
				clientName: "company",
				clientSubdomain: "subdomain",
			},
		},
		defaultValues: {
			numberRoles: -1,
			presentRole: false,
		},
		shouldUseSecurity: false,
	},
	get: {
		type: "get",
		mapping: {
			queryStringParameters: {
				query: "query",
			},
		},
		inputTransform: (input) => ({
			...input,
			queryStringParameters: {
				...input.queryStringParameters,
				...input.queryStringParameters.query,
			},
		}),
	},
	update: {
		type: "put",
		mapping: {
			queryStringParameters: {
				clientName: "clientName",
				updateType: "updateType",
				userId: "userId",
			},
		},
	},
	addCustomTag: {
		type: "put",
		mapping: {
			body: { tag: "tag", tagName: "tagName", tagType: "tagType" },
			queryStringParameters: {
				updateType: "updateType",
			},
		},
		defaultValues: {
			updateType: "customTag",
		},
	},
	updateLanguage: {
		type: "put",
		mapping: {
			body: { language: "language" },
			queryStringParameters: {
				updateType: "updateType",
			},
		},
		defaultValues: {
			updateType: "language",
		},
	},
	updateSubdomain: {
		type: "put",
		mapping: {
			body: { subdomain: "subdomain", updateType: "updateType" },
		},
		defaultValues: {
			updateType: "subdomain",
		},
	},
	patch: {
		type: "patch",
		mapping: {
			body: "updates",
			queryStringParameters: {
				changesOnly: "changesOnly",
			},
		},
		outputTransform: (output) => true,
		errorHandler: (error) => {
			console.log("error", error)
			return false
		},
	},
})

export const customOutputApi = makeApiEndpointSet("/output", {
	post: {
		type: "post",
		idForPath: "appId",
		mapping: {
			body: {
				outputName: "outputName",
				fileName: "fileName",
				userId: "userId",
				chapters: "chapters",
				bullhornOutputData: "bullhornOutputData",
				pdfB64: "pdfB64",
				imageB64: "imageB64",
				imageType: "imageType",
				imageFileType: "imageFileType",
			},
		},
	},
})

export const linksApi = makeApiEndpointSet("/links", {
	get: {
		type: "get",
		idForPath: "appId",
		mapping: {
			queryStringParameters: {
				attributes: "attributes",
			},
		},
	},
	getSingle: {
		type: "get",
		idForPath: "linkId",
		mapping: {
			queryStringParameters: {
				attributes: "attributes",
			},
		},
		outputTransform: (output) => output[0],
	},
	add: {
		type: "post",
		idForPath: "appId",
		mapping: {
			queryStringParameters: {
				name: "name",
				expiry: "expiry",
				updateEmails: "updateEmails",
				downloading: "downloading",
			},
		},
	},
	patch: {
		type: "patch",
		idForPath: "linkId",
		mapping: {
			body: "body",
		},
	},
	updateLinks: {
		type: "put",
		idForPath: "linkId",
		mapping: {
			queryStringParameters: {
				updateType: "updateType",
				newExpiry: "newExpiry",
			},
		},
		defaultValues: {
			updateType: "expiry",
		},
	},
})

export const viewsApi = makeApiEndpointSet("/views", {
	add: {
		type: "post",
		idForPath: "sessionId",
		mapping: {
			queryStringParameters: {},
		},
	},
})

export const adminAccessApi = makeApiEndpointSet("/access", {
	get: {
		type: "get",
		mapping: {
			queryStringParameters: {
				accessType: "accessType",
				userType: "userType",
				singleApplication: "singleApplication",
				singleTemplate: "singleTemplate",
			},
		},
	},
})

export async function updateFolder({ body }) {
	const secParams = await createSecurityParameters({})
	const path = "/folders"
	const fullPath = addIdsToPath(path, body.folderId)
	const newFolder = await API.put(global.config.apiGateway.NAME, fullPath, {
		headers: { "Content-Type": "application/json", ...secParams.headers },
		body: body,
	})
	return newFolder
}

export async function createFolder({ qsParams, body }: { qsParams?: object; body: { clientId: string } }) {
	const secParams = await createSecurityParameters({})

	const path = "/folders"
	const fullPath = addIdsToPath(path, body.clientId)
	try {
		const folder = await API.post(global.config.apiGateway.NAME, fullPath, {
			headers: { "Content-Type": "application/json", ...secParams.headers },
			queryStringParameters: qsParams ?? {},
			body: body,
		})
		return folder
	} catch (e) {
		if (e.response?.status === 403) {
			throw new Error("No credit available to create new folder, please contact account manager")
		} else {
			throw e
		}
	}
}

export async function getFolders(companyId) {
	const secParams = await createSecurityParameters({})
	var foldersObj

	const path = "/folders"
	const fullPath = addIdsToPath(path, companyId)
	try {
		const folders = await API.get(global.config.apiGateway.NAME, fullPath, {
			headers: secParams.headers,
		})
		foldersObj = indexArray(folders, "folder_id")
	} catch (error) {
		if (error.response?.status === 404) {
			console.log("404 received - assume no folders exist")
			foldersObj = {}
		} else {
			throw error
		}
	}

	return foldersObj
}

export async function createJob({ qsParams, body }: { qsParams?: object; body }) {
	const secParams = await createSecurityParameters({})
	const path = "/jobs"
	const fullPath = addIdsToPath(path, body.clientId)
	try {
		const job = await API.post(global.config.apiGateway.NAME, fullPath, {
			headers: { "Content-Type": "application/json", ...secParams.headers },
			queryStringParameters: qsParams ?? {},
			body: body,
		})
		return job
	} catch (e) {
		if (e.response?.status === 403) {
			throw new Error("No credit available to create new job, please contact account manager")
		} else {
			throw e
		}
	}
}

export async function getJobs(clientId, jobId) {
	const secParams = await createSecurityParameters({})
	var jobsObj
	const path = "/jobs"
	const fullPath = addIdsToPath(path, jobId ?? clientId)
	try {
		const jobs = await API.get(global.config.apiGateway.NAME, fullPath, {
			headers: secParams.headers,
		})
		jobsObj = indexArray(jobs, "job_id")
	} catch (error) {
		if (error.response?.status === 404) {
			console.log("404 received - assume no jobs exist")
			jobsObj = {}
		} else {
			throw error
		}
	}

	return jobsObj
}

export async function patchJob(updates) {
	const secParams = await createSecurityParameters({})
	const path = "/jobs"
	const fullPath = addIdsToPath(path, updates.jobId)
	const job = await API.patch(global.config.apiGateway.NAME, fullPath, {
		body: updates,
		headers: secParams.headers,
	})

	return job
}

export async function updateJob({ jobId, newStatus }: { jobId: string; newStatus: "open" | "closed" }) {
	const secParams = await createSecurityParameters({})
	const path = "/jobs"
	const fullPath = addIdsToPath(path, jobId)
	const job = await API.put(global.config.apiGateway.NAME, fullPath, {
		queryStringParameters: { jobId, newStatus },
		headers: secParams.headers,
	})

	return job
}

export const filesApi = makeApiEndpointSet("/files", {
	get: {
		type: "get",
		idForPath: "appId",
		mapping: {},
		errorHandler: (error) => {
			return {}
		},
	},
	add: {
		type: "post",
		idForPath: "appId",
		mapping: {
			body: { fileB64: "fileB64", fileType: "fileType", designation: "designation" },
		},
	},
})

export async function createUser({
	userEmail,
	userName,
	userRole,
	companyId,
	company,
	userType = "app",
	platform,
	externalClientId,
	externalUserId,
}: {
	userEmail: string
	userName: string
	userRole: string
	companyId?: string
	company: string
	userType?: string
	platform?: string
	externalClientId?: string
	externalUserId?: string
}) {
	const secParams = await createSecurityParameters({})
	const queryStringParameters: {
		email: string
		name: string
		userRole: string
		userType: string
		company_ID?: string
		company?: string
		platform?: string
		externalClientId?: string
		externalUserId?: string
	} = cleanObject(
		{
			email: userEmail,
			name: userName,
			userRole: userRole,
			userType: userType,
			platform: platform,
			externalClientId: externalClientId,
			externalUserId: externalUserId,
		},
		true
	)
	if (company != null) {
		queryStringParameters.company = company
	}
	const fullPath = addIdsToPath("/users", companyId)

	const clientId = await API.post(global.config.apiGateway.NAME, fullPath, {
		queryStringParameters,
		headers: secParams.headers,
	})
	return clientId
}

export async function getUsers(body: {
	clientId: string
	userId?: string
	attributes?: string[]
	externalUserId?: string
	externalClientId?: string
	platform?: string
	email?: string
}) {
	const secParams = await createSecurityParameters({})

	const { clientId, ...otherBody } = body
	const params = cleanObject(otherBody, true)
	const path = "/users"
	const fullPath = addIdsToPath(path, clientId)
	const response = await API.get(global.config.apiGateway.NAME, fullPath, {
		queryStringParameters: params,
		headers: secParams.headers,
	})
	if (body.userId != null || body.externalUserId != null) {
		return response[0]
	}
	return response
}

export async function updateUser({ email, userId, name, userRole, clientId }) {
	const path = "/users"
	const fullPath = addIdsToPath(path, clientId)
	const secParams = await createSecurityParameters({})
	var body: any = { email, userId }
	if (name !== undefined) {
		body.name = name
	}
	if (userRole !== undefined) {
		body.userRole = userRole
	}
	try {
		await API.put(global.config.apiGateway.NAME, fullPath, {
			body,
			headers: secParams.headers,
		})
	} catch {
		console.log("failed to update user")
	}
	return
}

export async function updateSignature(body: { clientId; email: string; name: string }) {
	const secParams = await createSecurityParameters({})
	const path = "/signatures"
	const fullPath = addIdsToPath(path, body.clientId)
	try {
		const response = await API.put(global.config.apiGateway.NAME, fullPath, {
			body,
			headers: secParams.headers,
		})
		return response
	} catch {
		console.log("failed to update signature")
	}
}

type PatchUserParams = {
	email: string
	name?: string
	userRole?: string
	clientId: string
	clientName?: string
	phone?: string
	jobTitle?: string
	linkedInUrl?: string
	userDescription?: string
	displayName?: string
	displayCompanyName?: string
} & ({ userId: string } | { platform: string; externalUserId: string; externalClientId })

export async function patchUser(inputBody: PatchUserParams) {
	const secParams = await createSecurityParameters({})
	const { clientId, ...otherBody } = inputBody
	const body = cleanObject(otherBody)
	const path = "/users"
	const fullPath = addIdsToPath(path, clientId)

	try {
		await API.patch(global.config.apiGateway.NAME, fullPath, {
			body,
			headers: secParams.headers,
		})
	} catch {
		console.log("failed to patch user")
	}
	return
}

export async function deleteUser({ userId, companyId, company, userEmail }) {
	const secParams = await createSecurityParameters({})
	const path = "/users"
	const fullPath = addIdsToPath(path, companyId)
	// try {
	await API.del(global.config.apiGateway.NAME, fullPath, {
		queryStringParameters: {
			userId: userId,
			company: company,
			companyId: companyId,
			EMAIL: userEmail,
		},
		headers: secParams.headers,
	})
	// } catch (e) {
	//   alert('Failed to delete user',e)
	// }
	return
}

export async function addLogo({ b64File, clientId, fileType, fileName, setAsCurrent = true, cache = true }) {
	const secParams = await createSecurityParameters({})
	const strippedB64 = stripB64Extra(b64File)
	const path = "/logos"
	const fullPath = addIdsToPath(path, clientId)
	try {
		const response = await API.post(global.config.apiGateway.NAME, fullPath, {
			queryStringParameters: {
				fileType: fileType,
				fileName: fileName,
				setAsCurrent: setAsCurrent,
			},
			body: strippedB64,
			headers: secParams.headers,
		})
		if (cache && caches != null) {
			caches.open("logoCache").then((cache) => {
				"add to cache for get call on upload, async"
				var qsParams = {
					fileName: response,
				}
				const requestParams = {
					queryStringParameters: qsParams,
					headers: secParams.headers,
				}
				const cacheRequest = new Request(makeUrl(fullPath, qsParams), {
					...requestParams,
					method: "GET",
				})

				cache.put(cacheRequest, new Response(b64File))
			})
		}
		return response
	} catch (e) {
		alert(`image file not accepted:\n ${e.response?.data.message ?? e.message}`)
		throw new Error(e)
	}
}

export function makeUrl(baseUrl, queryStringParameters: QSParams) {
	let qsString = Object.entries(queryStringParameters)
		.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
		.join("&")
	if (qsString.length > 0) {
		return `${baseUrl}?${qsString}`
	} else {
		return baseUrl
	}
}

export async function getLogo({ clientId, fileName }) {
	const secParams = await createSecurityParameters({})
	const path = "/logos"
	const fullPath = addIdsToPath(path, clientId)
	var qsParams: QSParams = {}
	const requestParams = {
		queryStringParameters: qsParams,
		headers: secParams.headers,
	}
	let cacheRequest: Request | null = null
	let cache

	if (fileName != null && caches != null) {
		cache = await caches.open("logoCache")
		qsParams.fileName = fileName
		cacheRequest = new Request(makeUrl(fullPath, qsParams), {
			...requestParams,
			method: "GET",
		})
		const cachedResponse = await cache.match(cacheRequest)
		if (cachedResponse) {
			return await cachedResponse.text()
		}
	}
	try {
		const response = await API.get(global.config.apiGateway.NAME, fullPath, requestParams)
		if (cacheRequest != null) {
			cache.put(cacheRequest, new Response(response))
		}
		return response
	} catch (error) {
		if (error.response?.status === 404) {
			console.log("404 received assume no logo exists")
			return null
		}
		throw error
	}
}

// export const logoApi = makeApiEndpointSet("/logos", {
// 	add: {
// 		type: "post",
// 		mapping: {
// 			queryString: {
// 				fileType: "fileType",
// 				fileName: "fileName",
// 				setAsCurrent: "setAsCurrent",
// 			},
// 			body: "b64File",
// 		},
// 	},
// 	get: {
// 		type: "get",
// 		mapping: {
// 			queryString: {
// 				fileName: "fileName",
// 			},
// 		},
// 	},
// })

export const templateApi = makeApiEndpointSet("/templates", {
	create: {
		type: "post",
		mapping: {
			queryStringParameters: {
				createdBy: "userId",
				templateVersion: "templateVersion",
				copyFrom: "copyFrom",
			},
		},
		defaultValues: {
			templateVersion: null,
		},
		inputTransform: cleanObject,
		errorHandler: (e) => {
			console.log(e)
			console.log(e.message)
		},
	},
	createFromDefault: {
		type: "post",
		mapping: {
			queryStringParameters: {
				defaultTemplateId: "defaultTemplateId",
			},
		},
	},
	get: {
		type: "get",
		mapping: {
			queryStringParameters: {
				attributes: "attributes",
			},
		},
		outputTransform: (response) => {
			return indexArray(response, "template_id")
		},
		errorHandler: (error) => {
			if (error.response?.status === 404) {
				console.log("404 received - assume no templates exist")
				return {}
			} else {
				throw error
			}
		},
	},
	getSingle: {
		type: "get",
		idForPath: "templateId",
		mapping: {
			queryStringParameters: {
				attributes: "attributes",
			},
		},
		outputTransform: (output) => ({ ...output, loadedIndividually: true }),
	},
	update: {
		type: "put",
		idForPath: "templateId",
		mapping: {
			body: "body",
		},
	},
	updateLiveStatus: {
		type: "put",
		idForPath: "templateId",
		mapping: {
			body: "body",
			queryStringParameters: {
				updateType: "updateType",
			},
		},
		defaultValues: {
			updateType: "liveStatus",
		},
	},
	delete: {
		type: "del",
		idForPath: "templateId",
		mapping: {},
		outputTransform: (response) => {
			return
		},
	},
})

export const defaultTemplateApi = makeApiEndpointSet("/defaulttemplates", {
	get: {
		type: "get",
		mapping: {
			queryStringParameters: {
				defaultTemplateId: "defaultTemplateId",
			},
		},
	},
})

export function combinePreviousText(previousText, currentText) {
	if (previousText == null || previousText.length === 0) {
		return currentText
	}
	const lastPrevChar = previousText[previousText.length - 1]
	const firstCurrentChar = currentText[0]
	// rules:
	// both chars capital or numbers or symbols: no space
	// either char is ': no space
	// first char is symbol: no space
	// either char is a space
	const lastPrevCharUpperOrNumber = lastPrevChar === lastPrevChar.toUpperCase()
	const firstCurrentCharUpperOrNumber = firstCurrentChar === firstCurrentChar.toUpperCase()

	const excludeSpace =
		(lastPrevCharUpperOrNumber && firstCurrentCharUpperOrNumber && ![",", "."].includes(lastPrevChar)) ||
		lastPrevChar === "'" ||
		firstCurrentChar === "'" ||
		!firstCurrentChar.match(/[a-z0-9]/i) ||
		firstCurrentChar === " " ||
		lastPrevChar === " "

	return previousText + (excludeSpace ? "" : " ") + currentText
}

export async function getTextTransform(
	data: {
		transformType: string
		text?: string
		testTransforms?: object
		previousText?: string
		testCVData?: Partial<App>
		appId?: string
		promptOptionsOverride?: object
		adjustText?: string
		storeResult?: string
	},
	setError: (arg0: string) => void,
	noCache = false,
	previousText?: string
) {
	ttCache = getTTCache()

	if (data == null) return
	const secParams = await createSecurityParameters({})
	const cacheKey = JSON.stringify(data)
	const itemFromCache = noCache ? null : ttCache.getItem(cacheKey)

	if (itemFromCache != null) {
		return itemFromCache
	}

	if (previousText) {
		data.previousText = previousText
	}

	const path = "/text-transform"

	const fullPath = addIdsToPath(path, data.appId)

	try {
		const response = await API.post("ara_db_api", fullPath, {
			body: data,
			headers: secParams.headers,
		})
		if (previousText != null) {
			response.text = combinePreviousText(previousText, response.text)
		}
		ttCache.setItem(cacheKey, response)
		return response
	} catch (error) {
		if (error.response?.status === 404) {
			setError("404 not found")
		}
		if (error.response?.status === 500) {
			setError("AI Assist isn't available right now. Please try again later.")
		}
		setError("AI Assist isn't available right now. Please try again later.")
	}
}

export async function publicGetClient({ apiKey, secret, attributes }) {
	const response = await API.get?.(global.config.apiGatewayPublic.NAME, "/clients", {
		headers: {
			"x-ara-api-key": apiKey,
			"x-ara-secret": secret,
		},
		queryStringParameters: {
			attributes,
		},
	})
	return response
}

interface PublicGetTokenQSParams {
	integration: string
	type: string
	token: string
	redirectUri?: string
}

export async function publicGetToken({ apiKey, secret, integration, type, token, redirectUri }) {
	const queryStringParameters: PublicGetTokenQSParams = {
		integration,
		type,
		token,
	}
	if (redirectUri) {
		queryStringParameters.redirectUri = redirectUri
	}
	const response = await API.get?.(global.config.apiGatewayPublic.NAME, "/oauthtokens", {
		headers: {
			"x-ara-api-key": apiKey,
			"x-ara-secret": secret,
		},
		queryStringParameters,
	})
	return response
}

interface PublicFileUpload {
	b64File: string
	fileMimeType: string
}

export async function publicAddApplication({
	apiKey,
	secret,
	files,
	clientId,
	parsingData = {},
	template,
	userId,
	externalId,
	platform,
	externalClientId,
	externalJobId,
	jobId,
	folderId,
}: {
	apiKey: string
	secret: string
	files: { [des: string]: PublicFileUpload }
	clientId: string
	parsingData?: object
	template?: string
	userId?: string
	externalId?: string
	platform?: string
	externalClientId?: string
	externalJobId?: string
	jobId?: string
	folderId?: string
}) {
	const response = await API.post?.(global.config.apiGatewayPublic.NAME, "/cv", {
		headers: {
			"x-ara-api-key": apiKey,
			"x-ara-secret": secret,
		},
		body: {
			externalId,
			platform,
			externalClientId,
			externalJobId,
			jobId,
			userId,
			clientId,
			files,
			folderId,
			parseCV: true,
			parsingData: {
				main: {
					data: parsingData,
				},
			},
			persistenceData: {},
			formattingSettings: {
				defaultTemplate: template,
			},
		},
	})
	return response
}

export async function publicGetUrl({
	userId,
	externalUserId,
	apiKey,
	secret,
	externalId,
	clientId,
	platform,
	externalClientId,
	endPoint,
	extraParameters,
}: {
	userId?: string
	externalUserId?: string
	apiKey: string
	secret: string
	externalId: string
	clientId: string
	platform: string
	externalClientId: string
	endPoint?: string
	extraParameters?: object
}) {
	const response = await API.get?.(global.config.apiGatewayPublic.NAME, "/urls", {
		headers: {
			"x-ara-api-key": apiKey,
			"x-ara-secret": secret,
		},
		queryStringParameters: cleanObject(
			{
				externalId,
				clientId,
				userId,
				externalUserId,
				platform,
				externalClientId,
				endPoint,
				...extraParameters,
			},
			true
		),
	})
	return response
}

export async function publicCustomGetUrl({
	parameters,
}: {
	parameters: {
		[key: string]: string
		platform: string
		endPoint: string
	}
}) {
	const response = await API.get?.(global.config.apiGatewayCustomPublic.NAME, "/urls", {
		queryStringParameters: cleanObject(parameters, true),
	})
	return response
}

export async function getOAuthStatus(
	attributes: {
		authCode?: string
		platform?: string
		externalUserId?: string
		clientId: string
		externalClientId: string
	},
	overrideAuth?: string
) {
	const secParams =
		overrideAuth != null
			? {
					headers: {
						Authorization: overrideAuth,
						"x-access-token": "none",
					},
				}
			: await createSecurityParameters({})

	const fullPath = addIdsToPath("/oauth", attributes.clientId)
	const response = await API.get?.(global.config.apiGateway.NAME, fullPath, {
		headers: secParams.headers,
		queryStringParameters: {
			...attributes,
		},
	})
	return response
}

export async function publicGetTemplates({ apiKey, secret, clientId, attributes }) {
	const queryStringParameters: { clientId: string; attributes?: string[] } = {
		clientId,
	}
	if (attributes) {
		queryStringParameters.attributes = attributes
	}
	const response = await API.get?.(global.config.apiGatewayPublic.NAME, "/templates", {
		headers: {
			"x-ara-api-key": apiKey,
			"x-ara-secret": secret,
		},
		queryStringParameters,
	})
	return response
}

export async function publicGetJobs({ apiKey, secret, clientId, attributes }) {
	const queryStringParameters: { clientId: string; attributes?: string[] } = {
		clientId,
	}
	if (attributes != null) {
		queryStringParameters.attributes = attributes
	}
	const response = await API.get?.(global.config.apiGatewayPublic.NAME, "/job", {
		headers: {
			"x-ara-api-key": apiKey,
			"x-ara-secret": secret,
		},
		queryStringParameters,
	})
	return response
}

export async function adminUpdateClient({ clientId, updateType, overwrite }) {
	const secParams = await createSecurityParameters({})
	const body: { updateType: "apiKey"; overwrite: boolean } = {
		updateType,
		overwrite,
	}

	const fullPath = addIdsToPath("/clients", clientId)
	try {
		const record = await API.put(global.config.apiGateway.NAME, fullPath, {
			body,
			headers: secParams.headers,
		})
		return record
	} catch (error) {
		if (error?.response?.status === 404) {
			console.log("404 received - assume no clients exist")
			return {}
		}
		throw error
	}
}

export async function publicGetUser({
	apiKey,
	secret,
	clientId,
	externalUserId,
	externalClientId,
	platform,
	attributes,
}) {
	const queryStringParameters: {
		externalId: string
		externalClientId: string
		platform: string
		clientId?: string
		attributes?: string[]
	} = {
		externalId: externalUserId,
		externalClientId,
		platform,
	}
	if (clientId) {
		queryStringParameters.clientId = clientId
	}
	if (attributes) {
		queryStringParameters.attributes = attributes
	}
	const response = await API.get?.(global.config.apiGatewayPublic.NAME, "/users", {
		headers: {
			"x-ara-api-key": apiKey,
			"x-ara-secret": secret,
		},
		queryStringParameters,
	})
	return response
}

export async function publicAddUser({
	apiKey,
	secret,
	clientId,
	externalUserId,
	externalClientId,
	platform,
	email,
	name,
	phone,
	location,
	jobTitle,
}: {
	apiKey: string
	secret: string
	externalUserId: string
	externalClientId: string
	platform: string
	clientId: string
	email: string
	name: string
	phone?: string
	location?: object
	jobTitle?: string
}) {
	const body: {
		externalId: string
		externalClientId: string
		platform: string
		clientId: string
		email: string
		name: string
		phone?: string
		location?: object
	} = cleanObject({
		externalId: externalUserId,
		externalClientId,
		platform,
		clientId,
		email,
		name,
		phone,
		location,
		jobTitle,
	})

	const response = await API.post?.(global.config.apiGatewayPublic.NAME, "/users", {
		headers: {
			"x-ara-api-key": apiKey,
			"x-ara-secret": secret,
		},
		body,
	})
	return response
}

export async function publicPatchUser({
	apiKey,
	secret,
	clientId,
	externalUserId,
	externalClientId,
	platform,
	email,
	name,
	phone,
	location,
	jobTitle,
}: {
	apiKey: string
	secret: string
	externalUserId: string
	externalClientId: string
	platform: string
	clientId: string
	email: string
	name: string
	phone?: string
	location?: object
	jobTitle?: string
}) {
	const body: {
		externalId: string
		externalClientId: string
		platform: string
		clientId: string
		email: string
		name: string
		phone?: string
		location?: object
	} = cleanObject({
		externalId: externalUserId,
		externalClientId,
		platform,
		clientId,
		email,
		name,
		phone,
		location,
		jobTitle,
	})

	const response = await API.patch?.(global.config.apiGatewayPublic.NAME, "/users", {
		headers: {
			"x-ara-api-key": apiKey,
			"x-ara-secret": secret,
		},
		body,
	})
	return response
}

export async function adminGetClientUsers({
	clientId,
	userId,
	attributes,
	stats,
}: {
	clientId: string
	userId?: string
	attributes?: string[]
	stats?: string[]
}) {
	const secParams = await createSecurityParameters({})
	const params: { clientId?: string; userId?: string; attributes?: string[]; stats?: string[] } = {}
	if (attributes != null) {
		params.attributes = attributes
	}
	if (stats != null) {
		params.stats = stats
	}
	if (userId != null) {
		params.userId = userId
	}
	const response = await API.get(global.config.apiGateway.NAME, `/${clientId}/usersforclient`, {
		queryStringParameters: params,
		headers: secParams.headers,
	})
	if (userId != null) {
		return response[0]
	}
	return response
}

export async function adminAddClientUser(params: {
	clientId: string
	clientName: string
	email: string
	name: string
	userRole?: string
}) {
	const secParams = await createSecurityParameters({})

	const response = await API.post(global.config.apiGateway.NAME, `/${params.clientId}/usersforclient`, {
		queryStringParameters: params,
		headers: secParams.headers,
	})
	return response
}

export async function adminPatchClientUser({
	clientId,
	userId,
	updates,
}: {
	clientId: string
	userId: string
	updates: Partial<UserFromDb>
}) {
	const secParams = await createSecurityParameters({})
	const body = { userId, ...updates }
	const response = await API.patch(global.config.apiGateway.NAME, `/${clientId}/usersforclient`, {
		body,
		headers: secParams.headers,
	})
	return response
}

type ClientUserUpdateBody =
	| ({ clientId: string; userId: string } & BetaAccessClientUserUpdateBody)
	| ({ clientId: string; userId: string } & ResendInviteClientUserUpdateBody)

interface BetaAccessClientUserUpdateBody {
	updateType: "betaAccess"
	betaAccess: boolean
}
interface ResendInviteClientUserUpdateBody {
	updateType: "resendInvite"
}

export async function adminUpdateClientUser(body: ClientUserUpdateBody) {
	const secParams = await createSecurityParameters({})

	const response = await API.put(global.config.apiGateway.NAME, `/${body.clientId}/usersforclient`, {
		body,
		headers: secParams.headers,
	})
	return response
}

export async function adminGetStats({ clientId, since, stats }: { clientId: string; since?: number; stats: string }) {
	const secParams = await createSecurityParameters({})
	const params: { since?: number; stats?: string } = {}

	if (stats != null) {
		params.stats = stats
	}
	if (since != null) {
		params.since = since
	}
	const response = await API.get(global.config.apiGateway.NAME, `/${clientId}/stats`, {
		queryStringParameters: params,
		headers: secParams.headers,
	})
	return response
}

export async function adminAddClient(body: {
	clientName: string
	clientSubdomain: string
	credits: {
		monthly: number
		annualy: number
	}
	paymentType: string
	productType: string
	limits?: {
		templates?: { limit?: number; enforced?: boolean }
		tags?: { limit?: number; enforced?: boolean }
		users?: { limit?: number; enforced?: boolean; showToUsers?: boolean }
	}
	options?: { outputs?: { [outputName: string]: CustomOutput } }
	outputData?: { [outputName: string]: { outputType: string } }
	status: string
	billingDate: string
	freeTrialEnd?: string
}) {
	const secParams = await createSecurityParameters({})

	const response = await API.post(global.config.apiGateway.NAME, "/clients", {
		body,
		headers: secParams.headers,
	})
	return response
}

export async function adminDeleteClient(body: { clientId: string; updateType: "full" | "archive" }) {
	const secParams = await createSecurityParameters({})

	try {
		const response = await API.del(global.config.apiGateway.NAME, `/${body.clientId}/clients`, {
			body,
			headers: secParams.headers,
		})
		return response
	} catch (error) {
		if (error.response?.status === 504) {
			console.log("504 received - deletion is continuing in background")
			return { response_error: "504" }
		}
		throw error
	}
}

export async function adminAddTemplate(body: { clientId: string; defaultTemplateId: string }) {
	const secParams = await createSecurityParameters({})

	const response = await API.post(global.config.apiGateway.NAME, `/${body.clientId}/templates`, {
		body,
		headers: secParams.headers,
	})
	return response
}

export async function adminGetTemplates({ clientId, attributes }: { clientId: string; attributes: string[] }) {
	const secParams = await createSecurityParameters({})

	const response = await API.get(global.config.apiGateway.NAME, `/${clientId}/templates`, {
		queryStringParameters: { attributes },
		headers: secParams.headers,
	})
	return response
}

export async function adminAddDefaultTemplate(body: {
	templateId: string
	keepHeaderImages?: boolean
	keepTagLanguage?: boolean
}) {
	const secParams = await createSecurityParameters({})

	const response = await API.post(global.config.apiGateway.NAME, "/defaulttemplates", {
		body,
		headers: secParams.headers,
	})
	return response
}

export async function adminDeleteDefaultTemplate(defaultTemplateId: string) {
	const secParams = await createSecurityParameters({})

	const response = await API.del(global.config.apiGateway.NAME, "/defaulttemplates", {
		queryStringParameters: { defaultTemplateId },
		headers: secParams.headers,
	})
	return response
}

export async function adminGetDefaultTemplates(queryStringParameters?: { attributes?: string[] }) {
	const secParams = await createSecurityParameters({})

	const response = await API.get(global.config.apiGateway.NAME, "/defaulttemplates", {
		queryStringParameters,
		headers: secParams.headers,
	})
	return response
}

type Method = "get" | "post" | "put" | "patch" | "del" | "head"

export async function forwardWithSecurity(body: {
	clientId: string
	externalClientId?: string
	externalUserId?: string
	url?: string
	platform: string
	method: Method
	data?: Blob
	json?: object
	queryStringParameters?: { [param: string]: string }
	headers?: { [header: string]: string }
	files?: any
	timeout?: number
	returnType?: "json" | "text" | "blob" | "base64"
}) {
	const secParams = await createSecurityParameters({})

	const fullPath = addIdsToPath("/forwarding", body.clientId)

	const response = await API.post(global.config.apiGateway.NAME, fullPath, {
		body,
		headers: secParams.headers,
	})
	return response
}
