import { useState } from "react"
import { PopUpMessage } from "../PopUpMessage/PopUpMessage"
import { MessagingContext } from "./MessagingContext"

export const MessagingContextProvider = ({ children }: { children: React.ReactNode }) => {
	const [messageContent, setMessageContent] = useState<{ icon: JSX.Element | null; text: string } | null>(null)

	return (
		<MessagingContext.Provider value={{ messageContent, setMessageContent }}>
			{children}
			<PopUpMessage>
				{messageContent && (
					<>
						{messageContent.icon} {messageContent.text}
					</>
				)}
			</PopUpMessage>
		</MessagingContext.Provider>
	)
}
