import { useState, useEffect, useRef, useCallback, useMemo, ChangeEventHandler } from "react"
import { getBase64 } from "../s3Lib/s3Lib"
import { addLogo, getLogo } from "../apiLib/apiLib"
import { deepCopy, isObjEqual } from "../objLib/objLib"
import { useAppContext } from "../contextLib/contextLib"
import { StickyStateOptions } from "./hooksLib.types"

function getBackupId(event) {
	//Hack to get around lack of id passing in the event from some elements
	const classList = event.nativeEvent.srcElement.classList
	for (var i = 0; i < classList.length; i++) {
		const className = classList[i]
		if (className.slice(0, 8) === "idbackup") {
			return className.split("-")[1]
		}
	}
}

export function useFormFields(initialState) {
	const [fields, setValues] = useState(initialState)

	return [
		fields,
		function (event) {
			setValues({
				...fields,
				[event.target.id || getBackupId(event)]:
					event.target.type === "checkbox" ? event.target.checked : event.target.value,
			})
		},
		function (data) {
			setValues({ ...fields, ...data })
		},
	]
}

export function useHandler<T>(initialState: T): [T, (event: { target: object }) => void] {
	const [field, setField] = useState(initialState)

	const handleField = useCallback((event) => {
		if (event.target !== undefined) {
			setField(event.target.type === "checkbox" ? event.target.checked : event.target.value)
		}
	}, [])

	return [field, handleField]
}

export function useUpdateableHandler(initialState) {
	//used for local data copies for input, the parent changing will update the hook, but setting the hook doesn't update the parent
	const [field, setField] = useState(initialState)

	useMemo(() => {
		if (initialState != null) {
			setField(initialState)
		}
	}, [initialState])

	const handleField = useCallback(
		(event) => setField(event.target.type === "checkbox" ? event.target.checked : event.target.value),
		[]
	)

	return [field, handleField]
}

interface RectDimensions {
	width: number
	height: number
}

export function getWindowDimensions(): RectDimensions {
	const { innerWidth: width, innerHeight: height } = window
	return {
		width,
		height,
	}
}

export function debounce(func: (...args: any[]) => void, wait = 50) {
	var timeout
	return function (...args: any[]) {
		clearTimeout(timeout)
		timeout = setTimeout(() => func(...args), wait)
	}
}

export function useWindowDimensions({ includeWidth = true, includeHeight = true }) {
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
	const dimRef = useRef<RectDimensions>({} as RectDimensions)

	useMountEffect(() => {
		dimRef.current = getWindowDimensions()
		function handleResize() {
			const newDims = getWindowDimensions()
			if (
				(includeWidth && newDims.width !== dimRef.current.width) ||
				(includeHeight && newDims.height !== dimRef.current.height)
			) {
				;(document.activeElement as HTMLElement)?.blur() // blurring input elements forces any updates to commit before the width change causes a rerender
				dimRef.current = newDims
				setWindowDimensions(newDims)
			}
		}
		const debouncedResize = debounce(handleResize)
		window.addEventListener("resize", debouncedResize)
		return () => window.removeEventListener("resize", debouncedResize)
	})

	return windowDimensions
}

export function useMountEffect(callback) {
	const mounted = useRef(false)
	useEffect(() => {
		mounted.current = true
		const returnFunction = callback?.(mounted)
		return () => {
			mounted.current = false
			returnFunction && typeof returnFunction === "function" && returnFunction?.()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
}

export function getStickyValue(storage, storageName, userId) {
	try {
		return JSON.parse(storage.getItem(`${userId}-${storageName}`))
	} catch {
		return null
	}
}

export function setStickyValue(storage, storageName, newState, userId) {
	return storage.setItem(`${userId}-${storageName}`, JSON.stringify(newState))
}

export function useStickyState<T>({
	initState,
	storageName,
	sessionOnly = false,
	forceOverride = false,
	forceOptions,
}: StickyStateOptions<T>) {
	//Wrapper around useState that stores the last usage in local storage
	const { user } = useAppContext()
	const userId = user?.id ?? "no-user"
	const storage = sessionOnly ? window.sessionStorage : window.localStorage
	let initialState = forceOverride ? initState : (getStickyValue(storage, storageName, userId) ?? initState)
	if (forceOptions && !forceOptions.includes(initialState)) {
		initialState = initState
	}
	const [state, setState] = useState<T>(initialState)
	const setAndStore: (arg: T) => void = useCallback(
		function (newState) {
			setState(newState)
			setStickyValue(storage, storageName, newState, userId)
		},
		[storage, storageName, userId]
	)
	return [state, setAndStore] as [T, (arg: T) => void]
}

export function addStickyValue({ storageName, value, sessionOnly = false, userId }) {
	const storage = sessionOnly ? window.sessionStorage : window.localStorage
	return setStickyValue(storage, storageName, value, userId)
}

export function retrieveStickyValue({ storageName, sessionOnly = false, userId }) {
	const storage = sessionOnly ? window.sessionStorage : window.localStorage
	return getStickyValue(storage, storageName, userId)
}

export function deleteStickyValue({ storageName, sessionOnly = false, userId }) {
	const storage = sessionOnly ? window.sessionStorage : window.localStorage
	return storage.removeItem(`${userId}-${storageName}`)
}

export function useImage() {
	const [image, setImage] = useState<string>(null)
	const [fetching, setFetching] = useState(false)
	const [invalid, setInvalid] = useState(false)

	const setImageAndSendToDb = useCallback(
		async (file: File, clientId: string, setAsCurrent: boolean) => {
			if (file !== null) {
				const b64File = await getBase64(file)
				const oldImage = image
				setImage(b64File)
				let response
				try {
					response = await addLogo({
						b64File,
						fileName: file.name,
						fileType: file.type,
						clientId,
						setAsCurrent,
						cache: true,
					})
					setImage(b64File) // To try to stop add/get order problem
				} catch {
					setImage(oldImage)
				}
				//response is filename
				return response
			}
		},
		[image]
	)

	const sendImageToDb = useCallback(async (file: File, clientId: string, setAsCurrent: boolean) => {
		if (file !== null) {
			const b64File = await getBase64(file)
			return addLogo({ b64File, fileName: file.name, fileType: file.type, clientId, setAsCurrent, cache: false })
		}
	}, [])

	const fetchImageFromDb = useCallback(
		async (client: { id: string }, fileName: string, onResponse?: () => void) => {
			if (client.id != null && !invalid) {
				setFetching(true)
				getLogo({ clientId: client.id, fileName })
					.then((newImage) => {
						onResponse?.()
						if (newImage != null) {
							setImage(newImage)
						}
						// don't update if null in case add/get calls reversed in signup
					})
					.catch(() => setInvalid(true))
					.finally(() => setFetching(false))
			}
		},
		[invalid]
	)

	return { image, setImageAndSendToDb, fetchImageFromDb, setImage, sendImageToDb, fetching, invalid }
}

export function useLogo() {
	const { image: logo, setImageAndSendToDb, fetchImageFromDb, sendImageToDb } = useImage()

	const setLogoAndSendToDb = useCallback(
		async (file, clientId, cache = true) => {
			await setImageAndSendToDb(file, clientId, true)
		},
		[setImageAndSendToDb]
	)
	const sendLogoToDb = useCallback(
		async (file, clientId) => {
			await sendImageToDb(file, clientId, true)
		},
		[sendImageToDb]
	)
	const fetchLogoFromDb = useCallback(
		(client) => {
			fetchImageFromDb(client, client?.logoName)
		},
		[fetchImageFromDb]
	)
	return { logo, setLogoAndSendToDb, fetchLogoFromDb, sendLogoToDb }
}

export const useClickOutside = (handler) => {
	const ref = useRef<HTMLDivElement>()
	useEffect(() => {
		let startedInside = false
		let startedWhenMounted = false

		const listener = (event) => {
			// Do nothing if `mousedown` or `touchstart` started inside ref element
			if (startedInside || !startedWhenMounted) return
			// Do nothing if clicking ref's element or descendent elements
			if (!ref.current || ref.current.contains(event.target)) return

			handler(event)
		}

		const validateEventStart = (event) => {
			startedWhenMounted = !!ref.current
			startedInside = !!ref.current && ref.current.contains(event.target)
		}

		document.addEventListener("mousedown", validateEventStart)
		document.addEventListener("touchstart", validateEventStart)
		document.addEventListener("click", listener)

		return () => {
			document.removeEventListener("mousedown", validateEventStart)
			document.removeEventListener("touchstart", validateEventStart)
			document.removeEventListener("click", listener)
		}
	}, [ref, handler])
	return ref
}

export function changeNestedEntry(obj, val, ...keys) {
	const [topKey, ...otherKeys] = keys
	if (obj == null) {
		if (typeof topKey === "number") {
			obj = []
		} else {
			obj = {}
		}
	}
	if (otherKeys == null || otherKeys.length === 0) {
		if (Array.isArray(obj)) {
			const arr = [...obj]
			arr[parseInt(topKey)] = val
			return arr
		}
		return { ...obj, [topKey]: val }
	} else {
		if (Array.isArray(obj)) {
			const arr = [...obj]
			arr[parseInt(topKey)] = changeNestedEntry(obj?.[topKey], val, ...otherKeys)
			return arr
		}
		return { ...obj, [topKey]: changeNestedEntry(obj?.[topKey], val, ...otherKeys) }
	}
}

export function useUpdateableObjectHandler<T>(
	initState: T,
	initPreventUpdates = false
): [
	T,
	(...path: Array<string | number>) => ChangeEventHandler,
	(key: keyof T, value: any) => void,
	(val: boolean) => void,
] {
	const initCopy = deepCopy(initState) // prevent issues caused by updates being applied to comparison object
	var [state, setStateBase] = useState<T>(initCopy)
	const stateRef = useRef(initCopy)
	const preventUpdatesRef = useRef(initPreventUpdates)

	const directUpdate = useCallback((newState) => {
		stateRef.current = newState
		setStateBase(newState)
	}, [])
	const setStateByKey = useCallback((key: keyof T, value: any) => {
		stateRef.current = { ...stateRef.current, [key]: value }
		setStateBase({ ...stateRef.current })
	}, [])

	const preventUpdates = useCallback((val: boolean) => {
		preventUpdatesRef.current = val
	}, [])

	useMemo(() => {
		if (!preventUpdatesRef.current && !isObjEqual(initCopy, stateRef.current)) {
			directUpdate(initCopy)
		}
	}, [initCopy, directUpdate])

	const handleState = useCallback(
		(...keys) =>
			(event) => {
				directUpdate(changeNestedEntry(stateRef.current, event.target.value, ...keys))
			},
		[directUpdate]
	)
	return [state, handleState, setStateByKey, preventUpdates]
}

export function useIsMounted() {
	const isMounted = useRef(true)
	useMountEffect(() => {
		return () => {
			isMounted.current = false
		}
	})
	return isMounted
}

export function useEntities(appId) {
	const { apps, folders, jobs } = useAppContext()
	const app = apps[appId]
	const folderId = app?.folder_id ?? appId.slice(0, 49)
	const folder = folders[folderId]
	const job = jobs?.[app?.jobId] ?? folder
	return [app, folder, job] as const
}
