import { createContext, useContext } from "react"

export interface MessagingContextProps {
	messageContent: { icon: JSX.Element | null; text: string } | null
	setMessageContent: (content: { icon: JSX.Element | null; text: string }) => void
}

export const MessagingContext = createContext<MessagingContextProps>({} as MessagingContextProps)

export const useMessagingContext = () => useContext(MessagingContext)
